import React, { useContext, useState, useEffect } from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";

import Context from "./context";
import Logo from "./logo";
import Close from "./close";
import Account from "./account";
import Star from "./star";
import Languages from "./languages";
import Search from "./search";
import BookModal from "./book-modal";
import Phone from "./phone";

const Header = ({ noButton }) => {
  const { data } = useContext(Context);
  const intl = useIntl();
  const allPages = data.allContentfulPage.nodes;
  const place_id = data.contentfulHotel.place_id;
  const [menu, setMenu] = useState("");
  const [search, setSearch] = useState("");
  const [placeData, setPlaceData] = useState(null);
  const { rating, user_ratings_total } = placeData || {};

  function getPage(category) {
    return allPages.find((page) => page.category === category);
  }

  function getEcoResponsabilityPage() {
    return allPages.find(
      (page) => page.contentful_id === "5T5rsj97KzvzmT3ZrVhE5e"
    );
  }

  function toggleMenu() {
    setMenu(menu === "" ? " active" : "");
  }

  function toggleSearch() {
    setSearch(search === "" ? " active" : "");
  }

  useEffect(() => {
    fetch(`https://ace-api.ultro.dev/place/details/json?place_id=${place_id}`)
      .then((response) => response.json())
      .then((data) => setPlaceData(data.result))
      .catch((error) => console.error(error));
  }, [place_id]);

  return (
    <header className="header">
      <div className="header-container">
        <div className="header-menu">
          <div className={`modal modal-menu${menu}`}>
            <button className="header-menu-hamburger" onClick={toggleMenu}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 5C3 4.44772 3.44772 4 4 4H20C20.5523 4 21 4.44772 21 5C21 5.55228 20.5523 6 20 6H4C3.44772 6 3 5.55228 3 5ZM3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12ZM4 18C3.44772 18 3 18.4477 3 19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19C21 18.4477 20.5523 18 20 18H4Z"
                />
              </svg>
              <span>Menu</span>
            </button>
            <div className="modal-content">
              <div className="modal-menu-bar">
                <ul className="modal-menu-bar-list">
                  <li className="modal-menu-bar-list-item">
                    <Account />
                  </li>
                  <li className="modal-menu-bar-list-item">
                    <Languages />
                  </li>
                </ul>
                <button
                  className="modal-close"
                  onClick={toggleMenu}
                  aria-label={intl.formatMessage({ id: "close" })}
                >
                  <Close />
                </button>
              </div>
              <div className="modal-scroll">
                <nav className="header-menu-navigation">
                  <ul className="header-menu-navigation-list">
                    <li className="header-menu-navigation-list-item">
                      <Link
                        to={`/${intl.locale}/${getPage("Offres").slug}/`}
                        onClick={toggleMenu}
                      >
                        {getPage("Offres").title}
                      </Link>
                    </li>
                    <li className="header-menu-navigation-list-item">
                      <Link
                        to={`/${intl.locale}/${getPage("Services").slug}/`}
                        onClick={toggleMenu}
                      >
                        {getPage("Services").title}
                      </Link>
                    </li>
                    <li className="header-menu-navigation-list-item">
                      <Link
                        to={`/${intl.locale}/${getPage("Esprits").slug}/`}
                        onClick={toggleMenu}
                      >
                        {getPage("Esprits").title}
                      </Link>
                    </li>
                    <li className="header-menu-navigation-list-item">
                      <Link
                        to={`/${intl.locale}/${
                          getPage("Groupes et séminaires").slug
                        }/`}
                        onClick={toggleMenu}
                      >
                        {getPage("Groupes et séminaires").title}
                      </Link>
                    </li>
                    <li className="header-menu-navigation-list-item">
                      <Link
                        to={`/${intl.locale}/${
                          getPage("Carnets de voyages").slug
                        }/`}
                        onClick={toggleMenu}
                      >
                        {getPage("Carnets de voyages").title}
                      </Link>
                    </li>
                    <li className="header-menu-navigation-list-item">
                      <Link
                        to={`/${intl.locale}/${
                          getEcoResponsabilityPage().slug
                        }/`}
                        onClick={toggleMenu}
                      >
                        {getEcoResponsabilityPage().title}
                      </Link>
                    </li>
                    <li className="header-menu-navigation-list-item">
                      <Link
                        to={`/${intl.locale}/${getPage("Contact").slug}/`}
                        onClick={toggleMenu}
                      >
                        {getPage("Contact").title}
                      </Link>
                    </li>
                    <li className="header-menu-navigation-list-item">
                      <a
                        href={`https://www.ace-hotel.com/${intl.locale}/`}
                        onClick={toggleMenu}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {intl.formatMessage({ id: "visit-ace" })}
                      </a>
                    </li>
                  </ul>
                </nav>
                {rating && (
                  <div className="reviews">
                    <div className="reviews-rating">
                      <p>{intl.formatNumber(rating)}</p>
                      <div className="reviews-rating-stars">
                        <div className="reviews-rating-stars-empty">
                          <Star />
                          <Star />
                          <Star />
                          <Star />
                          <Star />
                        </div>
                        <div
                          className="reviews-rating-stars-full"
                          style={{
                            width: `${rating * 20}%`,
                          }}
                        >
                          <Star />
                          <Star />
                          <Star />
                          <Star />
                          <Star />
                        </div>
                      </div>
                    </div>
                    <p>
                      {user_ratings_total}{" "}
                      {intl.formatMessage({ id: "reviews" })}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <button
              className="modal-backdrop"
              aria-label={intl.formatMessage({ id: "close" })}
              onClick={toggleMenu}
            ></button>
          </div>
        </div>
        <Link
          className="header-logo"
          to={`/${intl.locale}/`}
          aria-label={`ACE Hôtel${
            data.contentfulHotel.spirit.slug === "travel" ? " Travel" : ""
          }`}
        >
          <Logo
            variant={
              data.contentfulHotel.spirit.slug === "travel"
                ? "Travel"
                : "Simplement bien"
            }
          />
        </Link>
        <div className="header-tools">
          <nav className="header-tools-navigation">
            <ul className="header-tools-navigation-list">
              <li className="header-tools-navigation-list-item">
                <Link to={`/${intl.locale}/${getPage("Offres").slug}/`}>
                  {getPage("Offres").title}
                </Link>
              </li>
              <li className="header-tools-navigation-list-item header-tools-navigation-list-item-icon header-tools-navigation-list-item-phone">
                <Phone />
              </li>
              <li className="header-tools-navigation-list-item header-tools-navigation-list-item-icon">
                <Account />
              </li>
              <li className="header-tools-navigation-list-item">
                <Languages />
              </li>
            </ul>
          </nav>
          {data.contentfulHotel.slug === "brive" ? (
            <BookModal />
          ) : (
            !noButton && (
              <div className={`modal modal-search${search}`}>
                <button className="button" onClick={toggleSearch}>
                  {intl.formatMessage({ id: "book" })}
                </button>
                <div className="modal-content">
                  <button
                    className="modal-close"
                    aria-label={intl.formatMessage({ id: "close" })}
                    onClick={toggleSearch}
                  >
                    <Close />
                  </button>
                  <div className="container">
                    <Search booking_id={data.contentfulHotel.booking_id} />
                  </div>
                </div>
                <button
                  className="modal-backdrop"
                  aria-label={intl.formatMessage({ id: "close" })}
                  onClick={toggleSearch}
                ></button>
              </div>
            )
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
