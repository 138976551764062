import React from "react";

import Header from "./header";
import Footer from "./footer";

const Layout = ({ noButton, children }) => (
  <div className="wrapper">
    <Header noButton={noButton} />
    <main className="content">{children}</main>
    <Footer />
  </div>
);

export default Layout;
