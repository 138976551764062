import React, { useContext, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import axios from "axios";

import Context from "./context";
import InstagramSlider from "./instagram-slider";

export function SignUpForm() {
  const { data } = useContext(Context);
  const intl = useIntl();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    setSuccess("");
    setError("");

    axios
      .post(`${process.env.GATSBY_SERVER_URL}.netlify/functions/api/contact`, {
        email: data.email,
        locale: data.locale,
        website: data.website,
        newsletter: true,
      })
      .then(function (response) {
        // handle success
        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 204
        ) {
          setSuccess(intl.formatMessage({ id: "newsletter.success" }));
        } else {
          setError(intl.formatMessage({ id: "newsletter.error" }));
        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        setError(intl.formatMessage({ id: "newsletter.error" }));
      });
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-field form-field-group">
        <input
          type="hidden"
          value={intl.locale.toUpperCase()}
          {...register("locale")}
        />
        <input
          type="hidden"
          value={`ace-hotel-${data.contentfulHotel.slug}.com`}
          {...register("website")}
        />
        <input
          type="email"
          id="email"
          placeholder={intl.formatMessage({ id: "newsletter.email" })}
          {...register("email", { required: true })}
        />
        <label htmlFor="email">
          {intl.formatMessage({ id: "newsletter.email" })}
        </label>
        <div className="form-field-group-append">
          <button className="button" type="submit">
            {intl.formatMessage({ id: "newsletter.subscribe" })}
          </button>
        </div>
      </div>
      {success && <p className="form-success text-center">{success}</p>}
      {error && <p className="form-error text-center">{error}</p>}
    </form>
  );
}

const Newsletter = () => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(
        filter: { description: { regex: "/#instagram[-]?[0-9]/" } }
      ) {
        edges {
          node {
            title
            contentful_id
            description
            fluid(maxWidth: 267, quality: 80) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);

  const uniqueOrderData = [];
  const idSet = new Set();
  data.allContentfulAsset.edges.forEach((item) => {
    const number = item.node.description.match(/#instagram(-?\d+)/)?.[1];
    if (number && !idSet.has(item.node.contentful_id)) {
      idSet.add(item.node.contentful_id);
      uniqueOrderData.push({ ...item, sortNumber: parseInt(number, 10) });
    }
  });
  uniqueOrderData
    .sort((a, b) => a.sortNumber - b.sortNumber)
    .map(({ sortNumber, ...rest }) => rest);

  return (
    <div className="footer-social">
      <div className="container">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-8 col-sm-10 col-lg-12">
                <LazyLoad>
                  <InstagramSlider />
                </LazyLoad>
                <div className="grid grid-instagram d-none d-lg-grid">
                  {uniqueOrderData.map((node, i) => (
                    <figure className="instagram" key={i}>
                      <Img
                        fluid={{ ...node.node.fluid, aspectRatio: 1 / 1 }}
                        alt="ACE Hôtel"
                      />
                      <figcaption className="instagram-caption">
                        {node.node.title}
                      </figcaption>
                    </figure>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <h2 className="heading mt-lg-6">
              {intl.formatMessage({ id: "newsletter.heading" })}
            </h2>
            <p className="mb-lg-3">
              {intl.formatMessage({ id: "newsletter.follow" })}
            </p>
            <ul className="mt-0">
              <li>
                <a
                  href="https://www.facebook.com/acehotel.simplementbien"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Facebook"
                >
                  <svg
                    width="11"
                    height="24"
                    viewBox="0 0 11 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.99175 3.87038H11V0.164139C10.6535 0.113635 9.46196 0 8.07424 0C5.17874 0 3.19523 1.92985 3.19523 5.47681V8.74115H0V12.8844H3.19523V23.3097H7.11274V12.8854H10.1787L10.6654 8.74212H7.11182V5.88765C7.11274 4.69011 7.41705 3.87038 8.99175 3.87038Z" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/acehotelfrance/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Instagram"
                >
                  <svg
                    width="22"
                    height="24"
                    viewBox="0 0 22 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15.6094 0H6.39066C2.86683 0 0 3.03748 0 6.77107V16.5385C0 20.2721 2.86683 23.3096 6.39066 23.3096H15.6093C19.1332 23.3096 22 20.2721 22 16.5385V6.77107C22 3.03748 19.1332 0 15.6094 0ZM20.2812 16.5385C20.2812 19.268 18.1854 21.4885 15.6094 21.4885H6.39066C3.81455 21.4885 1.71875 19.268 1.71875 16.5385V6.77107C1.71875 4.04162 3.81455 1.82106 6.39066 1.82106H15.6093C18.1854 1.82106 20.2812 4.04162 20.2812 6.77107V16.5385Z" />
                    <path d="M11 5.37207C7.73034 5.37207 5.07031 8.19046 5.07031 11.6548C5.07031 15.1191 7.73034 17.9375 11 17.9375C14.2697 17.9375 16.9297 15.1191 16.9297 11.6548C16.9297 8.19046 14.2697 5.37207 11 5.37207ZM11 16.1164C8.6781 16.1164 6.78906 14.1149 6.78906 11.6548C6.78906 9.19465 8.6781 7.19315 11 7.19315C13.3219 7.19315 15.2109 9.19465 15.2109 11.6548C15.2109 14.1149 13.3219 16.1164 11 16.1164Z" />
                    <path d="M17.0156 6.19141C17.4902 6.19141 17.875 5.78375 17.875 5.28088C17.875 4.77802 17.4902 4.37036 17.0156 4.37036C16.541 4.37036 16.1562 4.77802 16.1562 5.28088C16.1562 5.78375 16.541 6.19141 17.0156 6.19141Z" />
                  </svg>
                </a>
              </li>
            </ul>
            <p className="mt-lg-6 mb-5 mb-lg-4">
              {intl.formatMessage({ id: "newsletter.mailbox" })}
            </p>
            <SignUpForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
