import React from "react";

const Close = ({ variant }) => {
  if (variant === "small") {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.4811 3.51825C16.1557 3.19281 15.6281 3.19281 15.3026 3.51825L9.99933 8.82155L4.69603 3.51825C4.37059 3.19281 3.84295 3.19281 3.51752 3.51825C3.19208 3.84368 3.19208 4.37132 3.51752 4.69676L8.82082 10.0001L3.51752 15.3034C3.19208 15.6288 3.19208 16.1564 3.51752 16.4819C3.84295 16.8073 4.37059 16.8073 4.69603 16.4819L9.99933 11.1786L15.3026 16.4819C15.6281 16.8073 16.1557 16.8073 16.4811 16.4819C16.8066 16.1564 16.8066 15.6288 16.4811 15.3034L11.1778 10.0001L16.4811 4.69676C16.8066 4.37132 16.8066 3.84368 16.4811 3.51825Z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
          fill="white"
        />
        <path
          d="M27.7789 12.2218C27.3884 11.8313 26.7552 11.8313 26.3647 12.2218L20.0008 18.5858L13.6368 12.2218C13.2463 11.8313 12.6131 11.8313 12.2226 12.2218C11.8321 12.6124 11.8321 13.2455 12.2226 13.6361L18.5865 20L12.2226 26.364C11.8321 26.7545 11.8321 27.3877 12.2226 27.7782C12.6131 28.1687 13.2463 28.1687 13.6368 27.7782L20.0008 21.4142L26.3647 27.7782C26.7552 28.1687 27.3884 28.1687 27.7789 27.7782C28.1695 27.3877 28.1695 26.7545 27.7789 26.364L21.415 20L27.7789 13.6361C28.1695 13.2455 28.1695 12.6124 27.7789 12.2218Z"
          fill="currentColor"
        />
      </svg>
    );
  }
};

export default Close;
