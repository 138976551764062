import React from "react";

const Star = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.00051 1.5L11.4251 6.4128L16.8467 7.20061L12.9236 11.0247L13.8497 16.4244L9.00051 13.875L4.15128 16.4244L5.0774 11.0247L1.1543 7.20061L6.5759 6.4128L9.00051 1.5Z" />
  </svg>
);

export default Star;
