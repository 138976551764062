import React, { useContext } from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";

import Context from "./context";

const locales = ["fr", "en"];

const Languages = ({ mobile }) => {
  const { data } = useContext(Context);
  const intl = useIntl();
  const allPages = data.allSitePage.nodes;

  function getPath(locale) {
    return allPages.find((page) => page.context.locale === locale)?.path;
  }

  return (
    <div
      className={`languages${mobile ? " languages-mobile" : ""}`}
      role="listbox"
      tabIndex="0"
      aria-label={intl.formatMessage({ id: "languages" })}
    >
      <span className="languages-current" role="option" aria-selected={true}>
        {intl.locale}
      </span>
      <ul className="languages-list">
        {locales
          .filter((locale) => locale !== intl.locale)
          .map((locale) => (
            <li className="languages-list-item" key={locale}>
              <Link to={getPath(locale)} role="option" aria-selected={false}>
                {locale}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Languages;
