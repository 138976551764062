import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Slider from "react-slick";

const InstagramSlider = () => {
  const settings = {
    infinite: false,
    slidesToShow: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 575.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(
        filter: { description: { regex: "/#instagram[-]?[0-9]/" } }
      ){
        edges{
          node {
            title
            contentful_id
            description
            fluid(maxWidth: 267, quality: 80) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);

  const uniqueOrderData = [];
  const idSet= new Set();
  data.allContentfulAsset.edges.forEach((item) => {
    const number = item.node.description.match(/#instagram(-?\d+)/)?.[1];
    if (number && !idSet.has(item.node.contentful_id)) {
      idSet.add(item.node.contentful_id);
      uniqueOrderData.push({ ...item, sortNumber: parseInt(number, 10) });
    }
  });
  uniqueOrderData.sort((a, b) => a.sortNumber - b.sortNumber).map(({ sortNumber, ...rest }) => rest)

  return (
    <Slider className="slider slider-instagram d-lg-none" {...settings}>
      {uniqueOrderData.map((node, i) => (
        <figure className="instagram" key={i}>
          <Img fluid={{ ...node.node.fluid, aspectRatio: 1 / 1 }} alt="ACE Hôtel" />
          <figcaption className="instagram-caption">{node.node.title}</figcaption>
        </figure>
      ))}
    </Slider>
  );
};

export default InstagramSlider;
