import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";

import Context from "./context";
import Close from "./close";

const BookModal = () => {
  const { data } = useContext(Context);
  const intl = useIntl();
  const allPages = data.allContentfulPage.nodes;
  const [modal, setModal] = useState("");

  function getSlug(category) {
    return allPages.find((page) => page.category === category).slug;
  }

  function toggleModal() {
    setModal(modal === "" ? " active" : "");
  }

  return (
    <div className={`modal modal-book${modal}`}>
      <button className="button" onClick={toggleModal}>
        {intl.formatMessage({ id: "book" })}
      </button>
      <div className="modal-content">
        <div className="container">
          <div className="row justify-content-center h-100">
            <div className="col-md-10 col-lg-8 col-xl-6">
              <div className="modal-book-inner">
                <button
                  className="modal-close"
                  onClick={toggleModal}
                  aria-label={intl.formatMessage({ id: "close" })}
                >
                  <Close />
                </button>
                <p className="h3 mt-0">
                  {intl.formatMessage({ id: "book-modal.heading" })}
                </p>
                <p className="small-md">
                  {intl.formatMessage({ id: "book-modal.paragraph" }) + " "}
                  <Link to={`/${intl.locale}/${getSlug("Contact")}/`}>
                    {intl.formatMessage({ id: "book-modal.link" })}
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="modal-backdrop"
        aria-label={intl.formatMessage({ id: "close" })}
        onClick={toggleModal}
      ></button>
    </div>
  );
};

export default BookModal;
