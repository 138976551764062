import React, { Component } from "react";
import { useIntl } from "react-intl";
import { inject, observer } from "mobx-react";

const withHook = (Component) => {
  return function WrappedComponent(props) {
    const intl = useIntl();
    return <Component {...props} intl={intl} />;
  };
};

// Injection du store Mobx (state management) pour partager les valeurs de recherches avec tous les composants
// Réactualisation automatique du composant avec @observer
@inject("store")
@observer
class Search extends Component {
  static today = new Date();
  static tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  static defaultStartDate = new Date(Search.today).toISOString().slice(0, 10);
  static defaultEndDate = new Date(Search.tomorrow).toISOString().slice(0, 10);

  constructor(props) {
    super(props);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleCouponCodeChange = this.handleCouponCodeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.props.store.searchQuery.booking_id = props.booking_id;
    this.state = {
      minEndDate: Search.defaultEndDate,
    };
  }

  componentDidMount() {
    this.startDate.min = Search.defaultStartDate;

    if (!this.props.store.startDate) {
      this.props.store.startDate = Search.defaultStartDate;
    }

    if (!this.props.store.endDate) {
      this.props.store.endDate = Search.defaultEndDate;
    }

    const startDate = new Date(this.props.store.startDate);
    const minEndDate = startDate.setDate(startDate.getDate() + 1);

    this.setState({
      minEndDate: new Date(minEndDate).toISOString().slice(0, 10),
    });
  }

  /**
   * MAJ du store pour la date d'arrivée et de départ
   */
  handleStartDateChange() {
    this.props.store.startDate = this.startDate.value;

    const startDate = new Date(this.props.store.startDate);
    const minEndDate = startDate.setDate(startDate.getDate() + 1);

    this.setState({
      minEndDate: new Date(minEndDate).toISOString().slice(0, 10),
    });

    if (this.startDate.value > this.props.store.endDate) {
      this.props.store.endDate = new Date(
        startDate.setDate(startDate.getDate())
      )
        .toISOString()
        .slice(0, 10);
    }
  }

  handleEndDateChange() {
    this.props.store.endDate = this.endDate.value;
  }

  /**
   * MAJ du store pour le code promo
   */
  handleCouponCodeChange() {
    this.props.store.couponCode = this.couponCode.value;
  }

  /**
   * Détection de la soumission du formulaire
   * @param event
   */
  handleSubmit(event) {
    event.preventDefault();

    if (window.dataLayer) {
      window.dataLayer.push({
        event: "begin_checkout",
        event_category: "outbound",
        eventCallback: () => {
          event.target.submit();
        },
        eventTimeout: 2000,
      });
    } else {
      event.target.submit();
    }
  }

  render() {
    const intl = this.props.intl;
    const { startDate, endDate, searchQuery } = this.props.store;

    const overnights =
      (new Date(endDate) - new Date(startDate)) / 1000 / 60 / 60 / 24;

    return (
      <form
        id="search"
        action="https://reservation.ace-hotel.com/module_booking_engine/index.php"
        target="_blank"
        className={"form form-search form-search-hotel"}
        onSubmit={this.handleSubmit}
      >
        <div className="form-field form-field-dates">
          <div>
            <input
              type="date"
              id="startDate"
              placeholder={intl.formatMessage({ id: "form.arrival" })}
              value={this.props.store.startDate || Search.defaultStartDate}
              required
              min={Search.defaultStartDate}
              ref={(input) => (this.startDate = input)}
              onChange={this.handleStartDateChange}
            />
            <label htmlFor="startDate">
              {intl.formatMessage({ id: "form.arrival" })}
            </label>
          </div>
          <span aria-hidden="true">–</span>
          <div>
            <input
              type="date"
              id="endDate"
              placeholder={intl.formatMessage({ id: "form.departure" })}
              value={this.props.store.endDate || Search.defaultEndDate}
              required
              min={this.state.minEndDate}
              ref={(input) => (this.endDate = input)}
              onChange={this.handleEndDateChange}
            />
            <label htmlFor="endDate">
              {intl.formatMessage({ id: "form.departure" })}
            </label>
          </div>
        </div>
        <div className="form-field form-field-coupon-code">
          <input
            type="text"
            id="couponCode"
            name="code_promo"
            placeholder={intl.formatMessage({ id: "form.coupon-code" })}
            value={this.props.store.couponCode}
            autoCorrect="off"
            autoComplete="off"
            autoCapitalize="off"
            spellCheck="false"
            ref={(input) => (this.couponCode = input)}
            onChange={this.handleCouponCodeChange}
          />
          <label htmlFor="couponCode">
            {intl.formatMessage({ id: "form.coupon-code" })}
          </label>
        </div>
        <button type="submit">{intl.formatMessage({ id: "book" })}</button>
        <input type="hidden" name="id_etab" value={searchQuery.booking_id} />
        <input
          type="hidden"
          name="date_deb"
          value={intl.formatDate(startDate, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        />
        <input type="hidden" name="nb_nuit" value={overnights} />
        <input
          type="hidden"
          name="langue"
          value={intl.locale === "fr" ? "francais" : "anglais"}
        />
      </form>
    );
  }
}

export default withHook(Search);
