import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.118 13.702L13 14.5C10.218 13.104 8.49999 11.5 7.49999 9L8.26999 4.87L6.81499 1H3.06399C1.93599 1 1.04799 1.932 1.21699 3.047C1.63699 5.83 2.87699 10.877 6.49999 14.5C10.305 18.305 15.786 19.956 18.802 20.613C19.967 20.866 21 19.958 21 18.765V15.181L17.118 13.702Z"
        stroke="#0B2246"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
