import React, { useContext } from "react";
import PhoneIcon from "./phone-icon";

import Context from "./context";

const Phone = () => {
  const { data } = useContext(Context);

  if (!data.contentfulHotel.phone) {
    return null;
  }

  return (
    <div className="phone">
      <a
        className="phone-button"
        href={`tel:${data.contentfulHotel.phone.replace(/\s/g, "")}`}
        aria-label="Call the hotel"
      >
        <PhoneIcon />
      </a>
    </div>
  );
};

export default Phone;
